import React from "react"

import Layout from "../components/layout"

import Twopoint0 from "../pages/twopoint0main"

const IndexPage = () => (
  <Layout>
   <Twopoint0 />
  </Layout>
)

export default IndexPage
