import React, { Component } from "react";
import { Pane, Text, Button, Heading } from 'evergreen-ui';
import Helmet from "react-helmet";

import logo from "../images/FNGLogo_Square2.png";
import "./twopoint0main.css";
import groupimg from "../images/5C9FA3BC-A743-4A27-BC9E-3E9C7AE925FC.jpg";

import pImg from "../images/Pedersens.jpg";
import wImg from "../images/WithinSodo_altmark_stacked.jpg";
import TecpImg from "../images/TECP_0315.jpg";
import NordstromImg from "../images/Nordstrom-Sponsor.jpg";
import cultureB from "../images/Logo-Culture-Blossoms.jpg";

//import posterImg from "../images/sippingNewDateUpdated2021.jpg";
import posterImg from "../images/SIS_Invite_2022.jpg";

class Twopoint0main extends Component {
  render() {
    return (
      <div id="twoPointOcontainer">
        <header id="topTier">

          <header id="logo">
            <img src={logo} alt="logo" id="twoPointZeroLogo" />
          </header>

          <header id="info">
            <Pane>
              <Text>
                <h1 class="c8"><span class="c14">Fran Nordstrom Guild 2.0</span></h1>
                <p class="c2"><span class="c1">Supporting Seattle Children&rsquo;s Uncompensated Care Fund</span></p>
              </Text>
            </Pane>
          </header>

        </header>

        <Pane display="flex" padding={8} background="tint1" borderRadius={3} elevation={2}>
          <Text size={400}>
            <img src={posterImg} alt="2020 event" />
          </Text>
        </Pane>

        <br />
        <br />

        <Pane display="flex" padding={16} background="tint2" borderRadius={3} elevation={2}>
          <Text size={400}>
            <div id="newCTA">
              <h3><span class="c3">Thank you!</span></h3>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2">
                <span>Because of your generous sponsorship, donations and attendance to Sipping into Summer, we raised $67,000 for Seattle Children's Hospital Uncompensated Care Fund.</span>
              </p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2">
                <span class="c1">Want to donate? You can make monetary donations by clicking the below button.</span>
              </p>

              <p class="c0"><span class="c1"></span></p>

              <p class="c0"><span class="c1"></span></p>
              <p class="c2">
                <span class="c4 c5">
                  <a class="c7" href="https://fwnguild.ejoinme.org/fng20donate">
                    <Button size={500}>Donate today!</Button>
                  </a>
                </span>
              </p>
              <p class="c9">
                <span class="c1"></span>
              </p>
            </div>
          </Text>
        </Pane>

        <br />

        <div id="frontPageCards">
        </div>

        <div id="about">
          <Pane
            height={500}
            display="flex"
            alignItems="center"
            border="default">
            <Text>
              <h2 class="c15"><span class="c16">About FNG 2.0</span></h2>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">FNG 2.0 was founded in 2017 as a chapter of the </span><span class="c5"><a href="http://frannordstromguild.org">Fran Nordstrom
                Guild</a>.</span> <span class="c1"> It was created to continue the
                                  guild&rsquo;s mission while focusing on involving young professionals and the next generation of advocates and
                fundraisers. FNG 2.0 is one of many guilds part of the </span><span class="c5"><a href="https://www.seattlechildrens.org/giving/guilds/about/">Seattle Children’s Guild Association</a>,</span> <span class="c1"> which is the largest all-volunteer fundraising network for any hospital in the nation. The Guild Association serves as the umbrella organization for the guild.</span></p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">The need for uncompensated care at </span><a href="https://www.seattlechildrens.org/giving/"><span class="c5">Seattle
                Children&rsquo;s</span></a>
                <span
                  class="c1"> has skyrocketed in the past few years. This free or partially paid funding assures all children
                  will receive quality care regardless of their families&rsquo; financial resources. Additionally, Seattle
                  Children&rsquo;s now places a high priority on research to cure and ultimately prevent children&rsquo;s health
                    challenges.</span>
              </p>
              <p class="c0"><span class="c1"></span></p>
              <p class="c2"><span class="c1">The Fran Nordstrom Guild is a 501(c)(3) nonprofit.</span></p>
              <p class="c0"><span class="c1"></span></p>
            </Text>
          </Pane>
        </div>

        <div class="parallax">
          <img alt="FNG20group" id="fng2Group" src={groupimg} />
        </div>

        <div id="join">
          <Pane>
            <Text>
              <h3 class="c12"><span class="c6">Interested in Joining FNG 2.0?</span></h3>
              <p class="c2"><span class="c1">No matter what your experience is with fundraising, we&#39;d love to hear from you!
                Email us at </span><span class="c10"><a class="c11"
                  href="mailto:FNGuild2.0@gmail.com">FNGuild2.0@gmail.com</a></span><span class="c1">.</span>
              </p>
            </Text>
          </Pane>
        </div>

        <div id="sponsorContainer">
          <Pane
            is="section"
            border="muted"
            marginLeft={12}
            marginY={24}
            paddingTop={12}
            paddingX={40}
          >
            <Text>
              <Heading>
                Thank You to Our Generous Sponsors
              </Heading>
            </Text>
            <br />
            <div id="sponsors">
              <article id="NordstromImage">
                <img id="nordImg" alt="Nordstrom" src={NordstromImg} />
              </article>
              <article id="pedersens">
                <a href=" https://seattle.pedersens.com/"><img id="pedersensImg" alt="Pedersens" src={pImg} /></a>
              </article>
              <article id="withinsodo">
                <a href="https://www.withinsodo.com/"><img id="withinImg" alt="Within" src={wImg} /></a>
              </article>
              <article id="TECP">
                <a href="http://www.tecp.org/"><img id="tecp-Img" alt="TECP" src={TecpImg} /></a>
              </article>
              <article id="cultureblossoms">
                <a href="https://www.coutureblossoms.com/"><img id="cblossoms-Img" alt="Couture Blossoms" src={cultureB} /></a>
              </article>
            </div>
          </Pane>
        </div>

        <div id="contactUs">
          <Pane
            height={300}
            display="flex"
            alignItems="center"
            border="default">
            <Text>
              <h2 class="c15"><span class="c16">Contact Us</span></h2>
              <p class="c2"><span class="c1">Questions? Email </span><span class="c10"><a class="c11"
                href="mailto:FNGuild2.0@gmail.com">FNGuild2.0@gmail.com</a></span><span class="c1">.</span></p>
              <p class="c2"><span class="c1">Follow us on </span><span class="c10"><a class="c11"
                href="https://www.facebook.com/FNG2.0/">Facebook</a></span>
              </p>
            </Text>
          </Pane>
        </div>

        <Helmet>
          <title>Fran Nordstrom Guild 2.0</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>

        <style jsx>{`
        
        `}</style>

      </div>
    );
  }
}

export default Twopoint0main
