import React from "react"

const TodoPage = () => (
  <div id="todo">
    <p class="c2"><span class="c1">Things we&rsquo;ll want to add when the event gets closer: </span></p>
    <ul class="c17 lst-kix_list_1-0 start">
      <li class="c2 c3"><span class="c1">Save the date</span></li>
      <li class="c2 c3"><span class="c1">Event registration section</span></li>
      <li class="c2 c3"><span class="c1">List of event sponsors</span></li>
      <li class="c2 c3"><span class="c1">Once the event is complete, we&rsquo;ll update the top section with a thank you
            image</span></li>
    </ul>
  </div>
)

export default TodoPage
